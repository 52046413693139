import * as React from "react";
// @ts-ignore
import {Col, Container, Form, Row, Button, Image} from "react-bootstrap";

import * as style from './ProductsTable.module.scss';
import "../../swiperStuff.scss"
import "./swiper.scss"
import * as navStyle from '../../../../components/Navigation/Navigation.module.scss';
import * as contentStyle from '../../../../components/Content/Content.module.scss';
import {formButton, formInput, formLabel} from "../../../login/index.module.scss";
import {faArrowRight} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {StaticImage} from "gatsby-plugin-image";
import * as mainStyle from '../../index.module.scss';

import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination, Navigation} from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {useEffect, useState} from "react";
import {Client, handleApiError} from "../../../../services/ApiService";
import {array} from "yup";


type ProductsTableProps = {
    title: string,
    description: string,
    caption: string,

    item1Title: string,
    item2Title: string,
    item3Title: string,

    Image1Link: string,
    Image2Link: string,

    item1Description: string,
    item2Description: string,

    // item1Icon: string,
    // item2Icon: string,
    // item3Icon: string,

    item1Price: string,
    item2Price: string,
    item3Price: string,
    PDFLink: string,

    products: [],
}

function ProductsTable(props: ProductsTableProps) {


    const breakpoints = {
        1199: {
            slidesPerView: 4
        },
        991: {
            slidesPerView: 3
        },
        767: {
            slidesPerView: 4
        },
        0: {
            slidesPerView: 2
        }
    };
    return <>
        <div style={{backgroundColor: '#F6F5F8'}} className={mainStyle.box}>
            <Container>
                <Row className={style.productsBox}>
                    <Col lg={4}>
                        <h2 className={style.headline}>{props.title}</h2>
                        <div className={style.caption}>{props.caption}</div>
                        <div className={style.description  + ' mb-4'}>{props.description}</div>

                        <Button className={style.formBTN + " d-none d-md-inline-block mt-5 my-4"} onClick={() => window.open(props.PDFLink, '_blank')}>
                            Alle Leistungen als PDF herunterladen
                        </Button>
                    </Col>
                    <Col lg={8}>
                        <Row>
                            <Row>
                                <Col lg={6} className={style.itemContainer}>
                                    <div className={style.bigItem}>
                                        <div className={"d-flex"}>
                                            <div>
                                                <img src={props.Image1Link}
                                                             className={style.itemImage}
                                                             placeholder={"traceSVG"} alt={"background"}/>
                                            </div>
                                            <div className={"d-flex justify-content-start align-items-center"}>
                                                <h3 className={style.itemHeadline}>
                                                    {props.item1Title}
                                                </h3>
                                            </div>
                                        </div>
                                        <Row>
                                            <div className={style.itemDescription}>
                                                {props.item1Description}
                                            </div>
                                        </Row>
                                        <div className={style.itemPrice}>
                                            ab {props.item1Price}
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} className={style.itemContainer}>
                                    <div className={style.bigItem}>
                                        <div className={"d-flex"}>
                                            <div>
                                                <img src={props.Image2Link}
                                                             className={style.itemImage}
                                                             placeholder={"traceSVG"} alt={"background"}/>
                                            </div>
                                            <div className={"d-flex justify-content-start align-items-center"}>
                                                <h3 className={style.itemHeadline}>
                                                    {props.item2Title}
                                                </h3>
                                            </div>
                                        </div>
                                        <Row>
                                            <div className={style.itemDescription}>
                                                {props.item2Description}
                                            </div>
                                        </Row>
                                        <div className={style.itemPrice}>
                                            ab {props.item2Price}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Swiper
                                    navigation={true}
                                    slidesPerView={3}
                                    modules={[Navigation]}
                                    breakpoints={breakpoints}
                                    className={style.mySwiper + " productsTableSwiper"}
                                >
                                    {props.products ?
                                        props.products.map((item, key) => {
                                            return (
                                                <SwiperSlide className={style.itemContainer}>
                                                    <div className={style.smallItem}>
                                                        <div
                                                            className={"d-flex justify-content-center align-items-center"}>
                                                            <img
                                                                src={item.IconLink}
                                                                className={style.itemImageSmall}
                                                                placeholder={"traceSVG"} alt={"background"}/>
                                                        </div>
                                                        <div
                                                            className={"d-flex justify-content-center align-items-center"}>
                                                            <h3 className={style.itemHeadlineSmall}>
                                                                {item.Title}
                                                            </h3>
                                                        </div>
                                                        <div className={style.itemPrice}>
                                                            ab {item.PriceText}
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        }) : null}
                                </Swiper>
                            </Row>
                            {props.products ?
                                <Row className={style.normalProducts}>
                                    {props.products.map((item, key) => {
                                        return (
                                            <Col xs={3} className={style.smallProduct} key={key}>
                                                <div className={style.smallItem}>
                                                    <div className={"d-flex justify-content-center align-items-center"}>
                                                        <img src={item.IconLink}
                                                             className={style.itemImageSmall}
                                                             placeholder={"traceSVG"} alt={"background"}/>
                                                    </div>
                                                    <div className={"d-flex justify-content-center align-items-center"}>
                                                        <h3 className={style.itemHeadlineSmall}>
                                                            {item.Title}
                                                        </h3>
                                                    </div>
                                                    <div className={style.itemPrice}>
                                                        ab {item.PriceText} €
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </Row> : null}

                            <Button className={style.formBTN + " d-md-none d-block mt-5 my-4"} onClick={() => window.open(props.PDFLink, '_blank')}>
                                Alle Leistungen als PDF herunterladen
                            </Button>

                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    </>;
}

export default ProductsTable;
