// extracted by mini-css-extract-plugin
export var bigItem = "ProductsTable-module--bigItem--mtZih";
export var caption = "ProductsTable-module--caption--y59g+";
export var description = "ProductsTable-module--description--siJsU";
export var formBTN = "ProductsTable-module--formBTN--Fz+Nv";
export var headline = "ProductsTable-module--headline--EbnlA";
export var itemContainer = "ProductsTable-module--itemContainer--ttYYZ";
export var itemDescription = "ProductsTable-module--itemDescription--r-WN5";
export var itemHeadline = "ProductsTable-module--itemHeadline--8YlZz";
export var itemHeadlineSmall = "ProductsTable-module--itemHeadlineSmall--T3T8X";
export var itemImageSmall = "ProductsTable-module--itemImageSmall--9lB-9";
export var itemPrice = "ProductsTable-module--itemPrice--qf+C6";
export var itemPriceContainer = "ProductsTable-module--itemPriceContainer--zuFNY";
export var mySwiper = "ProductsTable-module--mySwiper--bCEpa";
export var normalProducts = "ProductsTable-module--normalProducts--1h1bb";
export var productsBox = "ProductsTable-module--productsBox--Y+E5+";
export var smallItem = "ProductsTable-module--smallItem--3SFfe";
export var smallProduct = "ProductsTable-module--smallProduct--xf1ML";